// IMPORTS
@import (reference) "../settings.less";

// BLOG BANNER
.blog-banners-section {
    margin-top: -8px;
}

.blog-banners-wrapper {
    .grid();
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px 32px;
}

.blog-banner {
    .grid();
    gap: 12px 32px;
    grid-template-columns: 213px minmax(0, 1fr);
    grid-template-rows: auto 1fr auto;

    figure {
        grid-row: 1 / 4;
        aspect-ratio: ~"213/192";

        img {
            .img-crop();
        }
    }

    b, p {
        color: @header-color;
    }

    b {
        line-height: 1.3;
    }

    i {
        font-style: normal;
        color: @success-color;
        display: flex;
        align-items: center;
        gap: 12px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 1.3;

        &:after {
            width: 4px;
            height: 4px;
            content: '';
            background-color: @success-color;
            border-radius: 50%;
        }
    }

    &:hover {

        b {
            text-decoration: underline;
        }

        i {
            color: @header-color;

            &:after {
                background-color: @header-color;
            }
        }
    }
}